import monitorCheckTransformer from '@/services/transformers/monitorCheckTransformer.js'

const baseURL = 'checks'

export default {
  /**
   * Get specific monitor check.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  async find (id, params = {}) {
    const check = await axios.get(`${baseURL}/${id}`, {
      params
    }).then(response => response.data.data)

    return monitorCheckTransformer(check)
  }
}
