<template>
  <div class="MonitorCheckSingle" v-if="check">
    <div class="top">
      <div class="title">
        Details of uptime check executed at {{ check.created_at | shortDate }}
      </div>
    </div>
    <monitor-check v-if="check"
                   :check="check"/>
  </div>
</template>

<script>
import checksApi from '@/api/checksApi.js'
import MonitorCheck from '@/components/MonitorCheck/MonitorCheck.vue'

export default {
  components: {
    MonitorCheck
  },

  metaInfo: {
    title: 'Check Report'
  },

  props: {
    checkId: {
      required: true
    }
  },

  data () {
    return {
      check: null
    }
  },

  created () {
    this.loadCheck()
  },

  methods: {
    async loadCheck () {
      this.check = await checksApi.find(this.checkId)
    }
  }
}
</script>

<style lang="scss" scoped>
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.5;
      color: #444;
    }
  }
</style>
